import { LandingFirstTile } from "./1First";
import { LandingAboutTile } from "./2About";
import { LandingWhatTile } from "./3What";
import { LandingWhereTile } from "./4Where";

export const Landing = () => <>
    <LandingFirstTile/>
    <LandingWhatTile/>
    <LandingWhereTile/>
</>;
